import React, { useEffect, useState } from 'react';
import './CharacterDetails.css';
import { styleConfigs, StyleType } from '../constants/styles';
import { doc, onSnapshot } from 'firebase/firestore';
import { db } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { IMAGE_PROMPT_COLLECTION } from '../constants/clientConstants';
import { ImagePrompt } from '../types';
interface CharacterDetailsProps {
    id: string;
    isAdmin?: boolean;
    cachedImagePrompt: ImagePrompt | null;
}


const CharacterDetails: React.FC<CharacterDetailsProps> = ({ id, isAdmin, cachedImagePrompt }) => {
    const [imagePrompt, setImagePrompt] = useState<ImagePrompt | null>(cachedImagePrompt);
    const [isLoadingX, setIsLoadingX] = useState(false);
    const [isLoadingSkyGuyX, setIsLoadingSkyGuyX] = useState(false);
    const [isLoadingInstagram, setIsLoadingInstagram] = useState(false);
    const [isLoadingYouTube, setIsLoadingYouTube] = useState(false);
    const [isLoadingSkyGuyYouTube, setIsLoadingSkyGuyYouTube] = useState(false);
    const [isLoadingSkyGuyInstagram, setIsLoadingSkyGuyInstagram] = useState(false);
    const functions = getFunctions();
    const postToSocialMedia = httpsCallable(functions, 'postToSocialMedia');
    const animateImage = httpsCallable(functions, 'animateImage');

    useEffect(() => {
        const unsubscribe = onSnapshot(
            doc(db, IMAGE_PROMPT_COLLECTION, id),
            (docSnap) => {
                if (docSnap.exists()) {
                    setImagePrompt(docSnap.data() as ImagePrompt);
                }
            },
            (error) => {
                console.error('Error listening to image prompt:', error);
            }
        );

        return () => unsubscribe();
    }, [id]);

    if (!imagePrompt) return null;

    const styleName = styleConfigs[imagePrompt.style as StyleType]?.name || imagePrompt.style;

    const handlePostToX = async () => {
        setIsLoadingX(true);
        try {
            await postToSocialMedia({
                id: id,
                shouldPostToX: true
            });
        } catch (error) {
            console.error('Error posting to X:', error);
        } finally {
            setIsLoadingX(false);
        }
    };

    const handlePostToInstagram = async () => {
        setIsLoadingInstagram(true);
        try {
            await postToSocialMedia({
                id: id,
                shouldPostToInstagram: true
            });
        } catch (error) {
            console.error('Error posting to Instagram:', error);
        } finally {
            setIsLoadingInstagram(false);
        }
    };

    const handlePostToSkyGuyInstagram = async () => {
        setIsLoadingSkyGuyInstagram(true);
        try {
            await postToSocialMedia({
                id: id,
                shouldPostToInstagram: true,
                account: 'SKY_GUY'
            });
        } catch (error) {
            console.error('Error posting to Sky Guy Instagram:', error);
        } finally {
            setIsLoadingSkyGuyInstagram(false);
        }
    };

    const handlePostToYouTube = async () => {
            setIsLoadingYouTube(true);
        try {
            await postToSocialMedia({
                id: id,
                shouldPostToYouTube: true
            });
        } catch (error) {
            console.error('Error posting to YouTube:', error);
        } finally {
            setIsLoadingYouTube(false);
        }
    };

    const handlePostToSkyGuyYouTube = async () => {
        setIsLoadingSkyGuyYouTube(true);
        try {
            await postToSocialMedia({
                id: id,
                shouldPostToYouTube: true,
                account: 'SKY_GUY'
            });
        } catch (error) {
            console.error('Error posting to Sky Guy YouTube:', error);
        } finally {
            setIsLoadingSkyGuyYouTube(false);
        }
    };

    const handlePostToSkyGuyX = async () => {
        setIsLoadingSkyGuyX(true);
        try {
            await postToSocialMedia({
                id: id,
                shouldPostToX: true,
                account: 'SKY_GUY'
            });
        } catch (error) {
            console.error('Error posting to Sky Guy X:', error);
        } finally {
            setIsLoadingSkyGuyX(false);
        }
    };

    const handleAnimate = async () => {
        try {
            console.log(`Animating image ${id}`);
            await animateImage({
                id: id
            });
        } catch (error) {
            console.error('Error animating image:', error);
        }
    };

    let createdAt:any = imagePrompt.createdAt
    if (createdAt?.toDate) {
        createdAt = createdAt?.toDate().toLocaleString();
    }else{
        createdAt = createdAt?.toDateString();
    }

    return (
        <div className="character-details-dialog">
            <h2>Character Details</h2>
            <div className="details-content">
                {imagePrompt.title &&
                    <div className="detail-item prompt-item">
                        <label>Title:</label>
                        <span>{imagePrompt.title}</span>
                    </div>
                }
                <div className="detail-item prompt-item">
                    <label>Prompt:</label>
                    <span>{imagePrompt.prompt}</span>
                </div>
                <div className="detail-item">
                    <label>Style:</label>
                    <span>{styleName}</span>
                </div>
                <div className="detail-item">
                    <label>Created:</label>
                    <span>{createdAt}</span>
                </div>
                <div className="detail-item">
                    <label>Votes:</label>
                    <span>{imagePrompt.votes.toLocaleString()}</span>
                </div>
                {imagePrompt.videoStatus && (
                    <div className="detail-item">
                        <label>Video Status:</label>
                        <span>
                            {imagePrompt.videoStatus}
                            {isAdmin && (imagePrompt.videoStatus === 'FAILED' || imagePrompt.videoStatus === 'NOT_STARTED') && (
                                <button onClick={handleAnimate} className="post-button">
                                    Animate
                                </button>
                            )}
                        </span>
                    </div>
                )}
                {imagePrompt.videoError && (
                    <div className="detail-item">
                        <label>Video Error:</label>
                        <span>{imagePrompt.videoError}</span>
                    </div>
                )}
                {imagePrompt.visibility && (
                    <div className="detail-item">
                        <label>Visibility:</label>
                        <span>{imagePrompt.visibility}</span>
                    </div>
                )}
                {imagePrompt.userName && isAdmin && (
                    <div className="detail-item">
                        <label>Created By:</label>
                        <span>{imagePrompt.userName}</span>
                    </div>
                )}
                <div className="detail-item">
                    <label>X/Twitter:</label>
                    <span>
                        {imagePrompt.MY_GUYS_X_ID ? (
                            <a
                                href={`https://x.com/i/status/${imagePrompt.MY_GUYS_X_ID}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-link"
                            >
                                View Post
                            </a>
                        ) : (
                            <div className="social-actions">
                                {imagePrompt.MY_GUYS_X_ERROR && <span className="error-text">{imagePrompt.MY_GUYS_X_ERROR}</span>}
                                {isAdmin && (
                                    <button
                                        onClick={handlePostToX}
                                        className="post-button"
                                        disabled={isLoadingX}
                                    >
                                        {isLoadingX ? '⏳' : 'Post to X'}
                                    </button>
                                )}
                            </div>
                        )}
                    </span>
                </div>

                <div className="detail-item">
                    <label>YouTube:</label>
                    <span>
                        {imagePrompt.MY_GUYS_YOUTUBE_ID ? (
                            <a
                                href={`https://youtube.com/shorts/${imagePrompt.MY_GUYS_YOUTUBE_ID}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-link"
                            >
                                View Short
                            </a>
                        ) : (
                            <div className="social-actions">
                                {imagePrompt.MY_GUYS_YOUTUBE_ERROR && <span className="error-text">{imagePrompt.MY_GUYS_YOUTUBE_ERROR}</span>}
                                {isAdmin && (
                                    <button
                                        onClick={handlePostToYouTube}
                                        className="post-button"
                                        disabled={isLoadingYouTube}
                                    >
                                        {isLoadingYouTube ? '⏳' : 'Post to YouTube'}
                                    </button>
                                )}
                            </div>
                        )}
                    </span>
                </div>

                <div className="detail-item">
                    <label>Instagram:</label>
                    <span>
                        {imagePrompt.MY_GUYS_INSTAGRAM_ID ? (
                            <a
                                href={`https://instagram.com/p/${imagePrompt.MY_GUYS_INSTAGRAM_ID}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-link"
                            >
                                View Post
                            </a>
                        ) : (
                            <div className="social-actions">
                                {imagePrompt.MY_GUYS_INSTAGRAM_ERROR && <span className="error-text">{imagePrompt.MY_GUYS_INSTAGRAM_ERROR}</span>}
                                {isAdmin && (
                                    <button
                                        onClick={handlePostToInstagram}
                                        className="post-button"
                                        disabled={isLoadingInstagram}
                                    >
                                        {isLoadingInstagram ? '⏳' : 'Post to Instagram'}
                                    </button>
                                )}
                            </div>
                        )}
                    </span>
                </div>

                {isAdmin &&
                    <div className="detail-item">
                        <label>YouTube Sky Guy:</label>
                        <span>
                            {imagePrompt.SKY_GUY_YOUTUBE_ID ? (
                                <a
                                    href={`https://youtube.com/shorts/${imagePrompt.SKY_GUY_YOUTUBE_ID}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="social-link"
                                >
                                    View Short
                                </a>
                            ) : (
                                <div className="social-actions">
                                    {imagePrompt.SKY_GUY_YOUTUBE_ERROR && <span className="error-text">{imagePrompt.SKY_GUY_YOUTUBE_ERROR}</span>}
                                    {isAdmin && (
                                        <button
                                            onClick={handlePostToSkyGuyYouTube}
                                            className="post-button"
                                            disabled={isLoadingSkyGuyYouTube}
                                        >
                                            {isLoadingSkyGuyYouTube ? '⏳' : 'Post to Sky Guy YouTube'}
                                        </button>
                                    )}
                                </div>
                            )}
                        </span>
                    </div>
                }

                {isAdmin &&
                    <div className="detail-item">
                        <label>X/Twitter Sky Guy:</label>
                        <span>
                            {imagePrompt.SKY_GUY_X_ID ? (
                                <a
                                    href={`https://x.com/i/status/${imagePrompt.SKY_GUY_X_ID}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="social-link"
                            >
                                View Post
                            </a>
                        ) : (
                            <div className="social-actions">
                                {imagePrompt.SKY_GUY_X_ERROR && <span className="error-text">{imagePrompt.SKY_GUY_X_ERROR}</span>}
                                {isAdmin && (
                                    <button
                                        onClick={handlePostToSkyGuyX}
                                        className="post-button"
                                        disabled={isLoadingSkyGuyX}
                                    >
                                        {isLoadingSkyGuyX ? '⏳' : 'Post to Sky Guy X'}
                                    </button>
                                )}
                            </div>
                        )}
                        </span>
                    </div>
                }

                {isAdmin && 
                 <div className="detail-item">
                    <label>Sky Guy Instagram:</label>
                    <span>
                        {imagePrompt.SKY_GUY_INSTAGRAM_ID ? (
                            <a
                                href={`https://instagram.com/p/${imagePrompt.SKY_GUY_INSTAGRAM_ID}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-link"
                            >
                                View Post
                            </a>
                        ) : (
                            <div className="social-actions">
                                {imagePrompt.SKY_GUY_INSTAGRAM_ERROR && <span className="error-text">{imagePrompt.SKY_GUY_INSTAGRAM_ERROR}</span>}
                                {isAdmin && (
                                    <button
                                        onClick={handlePostToSkyGuyInstagram}
                                        className="post-button"
                                        disabled={isLoadingSkyGuyInstagram}
                                    >
                                        {isLoadingSkyGuyInstagram ? '⏳' : 'Post to Sky Guy Instagram'}
                                    </button>
                                )}
                            </div>
                        )}
                    </span>
                </div>
                }
            </div>
        </div>
    );
};

export default CharacterDetails; 