import React, { useEffect, useState } from 'react';
import { collection, startAfter, query, orderBy, limit, getDocs } from 'firebase/firestore';
import { db } from '../firebase';
import { getCachedImageUrl } from '../constants/clientConstants';
import './ImageRibbon.css';
import { generateRandomNumber } from '../utils/numbers';
import { IMAGE_PROMPT_COLLECTION } from '../constants/clientConstants';

interface ImageRibbonProps {
    count?: number;
    onImageClick?: (id: string) => void;
}

const ImageRibbon: React.FC<ImageRibbonProps> = ({ count = 20, onImageClick }) => {
    const [images, setImages] = useState<Array<{id: string, url: string}>>([]);
    const randomSeed = generateRandomNumber();
    useEffect(() => {
        const fetchImages = async () => {
            const direction = randomSeed > 0.5 ? 'asc' : 'desc';
             
            let q = query(
                collection(db, IMAGE_PROMPT_COLLECTION),
                orderBy('seed', direction),
                startAfter(randomSeed),
                limit(count)
            );
            const querySnapshot = await getDocs(q);
            const imageData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                url: getCachedImageUrl(doc.id, doc.data().imageUrl)
            }));
            setImages(imageData);
        };

        fetchImages();
    }, [count]);

    return (
        <div className="image-ribbon">
            <div className="ribbon-content">
                {[...images, ...images].map((image, index) => (
                    <img 
                        key={index} 
                        src={image.url} 
                        alt="" 
                        className="ribbon-image"
                        onClick={() => onImageClick?.(image.id)}
                        style={{ cursor: 'pointer' }}
                    />
                ))}
            </div>
        </div>
    );
};

export default ImageRibbon; 