import confetti from 'canvas-confetti';


export const fireConfetti = (element?: HTMLElement) => {

    const defaults: { zIndex: number; origin?: { x: number; y: number } } = {
        zIndex: 4000
    };
    if (element) {
        const rect = element.getBoundingClientRect();
        const centerX = (rect.left + rect.right) / 2;
        const centerY = (rect.top + rect.bottom) / 2;

        // Convert to relative coordinates (0-1)
        const origin = {
            x: centerX / window.innerWidth,
            y: centerY / window.innerHeight
        };
        defaults.origin=origin;
    }

    const count = 200;
   
    function fire(particleRatio: number, opts: confetti.Options) {
        confetti({
            ...defaults,
            ...opts,
            particleCount: Math.floor(count * particleRatio),
            scalar: 1.2
        });
    }

    fire(0.25, {
        spread: 26,
        startVelocity: 55,
        colors: ['#FFD700', '#DAA520', '#B8860B']
    });

    fire(0.2, {
        spread: 60,
        colors: ['#61dafb', '#4fa8c7', '#DAA520']
    });

    fire(0.35, {
        spread: 100,
        decay: 0.91,
        scalar: 0.8,
        colors: ['#FFD700', '#61dafb', '#DAA520']
    });

    fire(0.1, {
        spread: 120,
        startVelocity: 25,
        decay: 0.92,
        scalar: 1.2,
        colors: ['#B8860B', '#4fa8c7', '#FFD700']
    });
};
