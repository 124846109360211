import { User } from 'firebase/auth';
import React, { useMemo } from 'react';
import { styleConfigs } from '../constants/styles';
import Card from './Card';
import './MainCard.css';
import Title from './Title';
import ImageRibbon from './ImageRibbon';
import ScrollIndicator from './ScrollIndicator';

interface MainCardProps {
    isLastCard?: boolean;
    setIsCreateDialogOpen: (open: boolean) => void;
    onImageClick?: (id: string) => void;
}

// Shuffle array helper function
const shuffleArray = <T,>(array: T[]): T[] => {
    const newArray = [...array];
    for (let i = newArray.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
    }
    return newArray;
};

const MainCard: React.FC<MainCardProps> = ({
    isLastCard = false,
    setIsCreateDialogOpen,
    onImageClick
}) => {
    const titleStyles = useMemo(() => 
        shuffleArray(
            Object.values(styleConfigs)
                .map(config => config.name)
        ), 
    []);

    return (
        <>
            <Card 
                background="#282c34"
                isLastCard={isLastCard}
                id={'home'}
                className="main-card"
            >
                <div className="main-card-content">
                    <Title
                        styles={titleStyles}
                        interval={4000}
                    />
                    <button 
                        className="create-character-button"
                        onClick={() => setIsCreateDialogOpen(true)}
                    >
                        ✨ Create Character
                    </button>
                    <ImageRibbon count={15} onImageClick={onImageClick} />
                    <ScrollIndicator />
                </div>
            </Card>

            
        </>
    );
};

export default MainCard; 