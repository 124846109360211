import React from 'react';
import './CardDialog.css';

interface CardDialogProps {
    isOpen: boolean;
    onClose: () => void;
    children: React.ReactNode;
    background?: string;
}

const CardDialog: React.FC<CardDialogProps> = ({
    isOpen,
    onClose,
    children,
    background = '#282c34'
}) => {
    if (!isOpen) return null;

    return (
        <div className="card-dialog-overlay" onClick={onClose}>
            <div 
                className="card-dialog"
                onClick={e => e.stopPropagation()}
                style={{ background }}
            >
                <button 
                    className="card-dialog-close"
                    onClick={onClose}
                    aria-label="Close dialog"
                >
                    <svg 
                        width="24" 
                        height="24" 
                        viewBox="0 0 24 24" 
                        fill="none" 
                        stroke="currentColor" 
                        strokeWidth="2" 
                        strokeLinecap="round" 
                        strokeLinejoin="round"
                    >
                        <line x1="18" y1="6" x2="6" y2="18" />
                        <line x1="6" y1="6" x2="18" y2="18" />
                    </svg>
                </button>
                <div className="card-dialog-content">
                    {children}
                </div>
            </div>
        </div>
    );
};

export default CardDialog; 