import { collection, doc, Timestamp } from 'firebase/firestore';
import { ImagePrompt } from 'types';
import { generateImage, HEIGHT, WIDTH } from 'utils/promptGenerator';
import { db } from '../firebase';
import { IMAGE_PROMPT_COLLECTION } from '../constants/clientConstants';

export const getNewPrompt= ():string => {
    const subjects =["boy", "girl"]
    const actions = ["jumping", "running", "dancing", "singing", "playing", "reading", "writing", "drawing", "painting", "cooking", "cleaning", "sleeping", "eating", "drinking", "talking", "thinking", "feeling", "laughing", "crying", "sleeping", "eating", "drinking", "talking", "thinking", "feeling", "laughing", "crying", "sleeping", "eating", "drinking", "talking", "thinking", "feeling", "laughing", "crying"]
    const objects = ["ball", "book", "computer", "phone", "car", "house", "tree", "flower", "dog", "cat", "bird", "fish", "book", "computer", "phone", "car", "house", "tree", "flower", "dog", "cat", "bird", "fish", "book", "computer", "phone", "car", "house", "tree", "flower", "dog", "cat", "bird", "fish"]
    const subject = subjects[Math.floor(Math.random() * subjects.length)];
    const action = actions[Math.floor(Math.random() * actions.length)];
    const object = objects[Math.floor(Math.random() * objects.length)];
    const prompt = `A ${subject} ${action} with a ${object}`;
    return prompt;
}

export const generateClientPrompt = ():ImagePrompt => {
    const width = WIDTH;
    const height = HEIGHT;
    const model = "flux";
    const prompt = getNewPrompt();
    const imageUrl = generateImage(prompt, model,
         width, height, false);

    const id = doc(collection(db, IMAGE_PROMPT_COLLECTION)).id;
    const data = {
        prompt: "sample prompt",
        id,
        title: "sample title "+new Date().getTime().toString(),
        fullPrompt: "sample full prompt",
        style: "This is a still from a 3-d animated video that takes place in a Funko-Pop universe. The video's artistic style features rich detail, vibrant colors, dramatic backdrops, an epic cinematic feel and incredible lighting.",
        styleName: "Funko-Pop",
        animationPrompt: "sample animation prompt",
        imageUrl: imageUrl,
        seed: 191813,
        width: 768,
        height: 1280,
        model: "flux",
        votes: 0,
        userId: "123",
        userName: "Anonymous",
        createdAt: Timestamp.now(),
    }    
    return data;
}