import React, { useState } from 'react';
import './ImageContainer.css';

interface ImageContainerProps {
    imageUrl: string;
    videoUrl?: string;
    showVideo?: boolean;
    prompt?: string;
}

const ImageContainer: React.FC<ImageContainerProps> = ({
    imageUrl,
    videoUrl,
    showVideo,
    prompt
}) => {
    const [isLoading, setIsLoading] = useState(true);
    const [hasError, setHasError] = useState(false);
    const displayVideo = videoUrl && showVideo;

    const handleError = () => {
        setIsLoading(false);
        setHasError(true);
    };

    if (hasError) {
        return (
            <div className="character-image-container">
                <div className="error-display">
                    <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor" fill="none">
                        <circle cx="12" cy="12" r="10" strokeWidth="2"/>
                        <line x1="15" y1="9" x2="9" y2="15" strokeWidth="2"/>
                        <line x1="9" y1="9" x2="15" y2="15" strokeWidth="2"/>
                    </svg>
                    <span>Failed to load</span>
                </div>
            </div>
        );
    }

    return (
        <div className="character-image-container">
            {isLoading && (
                <div className="loading-spinner">
                    <svg width="24" height="24" viewBox="0 0 24 24" stroke="currentColor">
                        <circle cx="12" cy="12" r="10" fill="none" strokeWidth="2" />
                        <path d="M12 2a10 10 0 0 1 10 10" strokeWidth="2" />
                    </svg>
                </div>
            )}
            {displayVideo ? (
                <video
                    key={videoUrl}
                    src={videoUrl}
                    autoPlay
                    loop
                    muted
                    playsInline
                    className={`character-video ${isLoading ? 'loading' : ''}`}
                    onLoadedData={() => setIsLoading(false)}
                    onError={handleError}
                />
            ) : (
                <img
                    key={imageUrl}
                    src={imageUrl}
                    alt={prompt}
                    className={`character-image ${isLoading ? 'loading' : ''}`}
                    onLoad={() => setIsLoading(false)}
                    onError={handleError}
                />
            )}
        </div>
    );
};

export default ImageContainer; 