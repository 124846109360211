import React from 'react';
import './ScrollIndicator.css';

const ScrollIndicator: React.FC = () => {
    return (
        <div className="scroll-indicator">
            <div className="scroll-text">Scroll for more</div>
            <svg 
                width="24" 
                height="24" 
                viewBox="0 0 24 24" 
                fill="none" 
                stroke="currentColor" 
                strokeWidth="2"
                className="scroll-arrow"
            >
                <polyline points="6 9 12 15 18 9" />
            </svg>
        </div>
    );
};

export default ScrollIndicator; 