export const WIDTH = 768;
export const HEIGHT = 1280;

export function generateImage(prompt: string, selectedModel: string, width: number = WIDTH, height: number = HEIGHT, enhance: boolean = false) {
    const url = `https://image.pollinations.ai/prompt/${encodeURIComponent(prompt)}?model=${selectedModel}&width=${width}&height=${height}&&enhance=${enhance}&nologo=true`;
    console.log(url);
    return url;
}

export const PROMPT_CONSTANTS = {
    MAX_WORDS: 125,
} as const;

export const getFullImagePrompt = (prompt: string, stylePrompt: string) => {
    return `Scene=${prompt}. 
        Mood:friendly and cheerful. 
        Camera Angle: zoomed in on subject. 
        Instructions: ${stylePrompt} 
        Negative: watermark, logo`;
}

