import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
    apiKey: "AIzaSyBtJpvzsF4DGqYeVE84UqBHyxm3WZ7IQPo",
    authDomain: "funko-fec28.firebaseapp.com",
    projectId: "funko-fec28",
    storageBucket: "funko-fec28.firebasestorage.app",
    messagingSenderId: "960791766493",
    appId: "1:960791766493:web:dcc1b1f0a5a361641cc234",
    measurementId: "G-KGNN0L5G8L"
  };
  
// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);

// Connect to emulators in development
if (process.env.NODE_ENV === 'development') {
    console.log('Connecting to Firebase emulators');
    // connectFirestoreEmulator(db, 'localhost', 8080);
    // connectFunctionsEmulator(functions, 'localhost', 5001);
}
