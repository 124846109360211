import { getAuth, GoogleAuthProvider, onAuthStateChanged, signInWithPopup, User } from 'firebase/auth';
import { collection, doc, getDoc, limit, onSnapshot, orderBy, query, startAfter, Timestamp, where } from 'firebase/firestore';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FixedSizeList as List } from 'react-window';
import Card from './components/Card';
import CardCharacterForm from './components/CardCharacterForm';
import CardDialog from './components/CardDialog';
import CharacterCard from './components/CharacterCard';
import MainCard from './components/MainCard';
import MainHeader from './components/MainHeader';
import { IMAGE_PROMPT_COLLECTION, SCROLL_DELAY } from './constants/clientConstants';
import { StyleType } from './constants/styles';
import { db } from './firebase';
import './Main.css';
import { ImagePrompt } from './types';
import { generateRandomNumber } from './utils/numbers';
import { generateClientPrompt } from 'hooks/clientPromptGenerator';
interface MainProps {
}

const CARD_BACKGROUNDS = ['#1e2326', '#343941']; // Darkened first color by 25%
const CARDS_PER_PAGE = 5;
const HEIGHT_OFFSET = 60;
const LOAD_MORE_THRESHOLD = 5;  // Load more when 5 items from end

// Add helper function at the top of the file
const deduplicatePrompts = (prompts: ImagePrompt[]): ImagePrompt[] => {
    const seen = new Set<string>();
    return prompts.filter(prompt => {
        if (seen.has(prompt.id)) {
            return false;
        }
        seen.add(prompt.id);
        return true;
    });
};

// 1. Move Row completely outside of Main component
const CardRow = React.memo(({
    index,
    style,
    setIsCreateDialogOpen,
    onImageClick,
    prompt,
    user,
    handleLastCard,
    handleDeletePrompt,
    setSelectedCharacter,
    background
}: {
    index: number;
    style: React.CSSProperties;
    setIsCreateDialogOpen: (open: boolean) => void;
    onImageClick: (id: string) => void;
    prompt?: ImagePrompt;
    user: User | null;
    handleLastCard: () => Promise<void>;
    handleDeletePrompt: (id: string) => void;
    setSelectedCharacter: (character: any) => void;
    background: string;
}) => {
    // Use CSS that won't interfere with mobile scrolling
    const scrollStyle: React.CSSProperties = {
        ...style,
        scrollSnapAlign: window.innerWidth > 768 ? 'start' : 'none',
        scrollSnapStop: window.innerWidth > 768 ? 'always' : 'normal',
        height: '100%'
    };

    if (index === 0) {
        return (
            <div style={scrollStyle}>
                <MainCard
                    setIsCreateDialogOpen={setIsCreateDialogOpen}
                    onImageClick={onImageClick}
                />
            </div>
        );
    }

    if (!prompt) return null;

    return (
        <div style={scrollStyle}>
            <Card
                key={prompt.id}
                id={prompt.id}
                isLastCard={false}
                onLastCardVisible={handleLastCard}
                background={background}
            >
                <CharacterCard
                    key={prompt.id}
                    id={prompt.id}
                    imagePrompt={prompt}
                    user={user}
                    onDelete={handleDeletePrompt}
                    onShowDetails={setSelectedCharacter}
                />
            </Card>
        </div>
    );
});

const getImageForPrompt = (prompts:ImagePrompt[], index:number) => {
    let newPrompt:any = prompts[index-1] 
    if (!newPrompt) {
        newPrompt = generateClientPrompt();
        prompts.push(newPrompt);
    }
    return newPrompt;
}

const Main: React.FC<MainProps> = () => {
    const [user, setUser] = useState<User | null>(null);
    const [randomSeed, setRandomSeed] = useState(generateRandomNumber());
    const [coins, setCoins] = useState(0);
    const [recentPrompts, setRecentPrompts] = useState<ImagePrompt[]>([]);
    const [lastDoc, setLastDoc] = useState<any>(null);
    const [queryUnsubscribe, setQueryUnsubscribe] = useState<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [initialId, setInitialId] = useState<string | null>(null);
    const [currentFilter, setCurrentFilter] = useState<'all' | 'my' | 'top' | 'recent' | 'oldest'>('recent');
    const [shouldFetch, setShouldFetch] = useState(true);
    const [selectedCharacter, setSelectedCharacter] = useState<{
        id: string;
        prompt: string;
        style: StyleType;
        createdAt: Timestamp;
        userName: string | null;
        votes: number;
        videoStatus?: string;
        videoError?: string;
        visibility?: 'PUBLIC' | 'PRIVATE';
        xId?: string;
        xError?: string;
        instagramId?: string;
        instagramError?: string;
        youtubeId?: string;
        youtubeError?: string;
    } | null>(null);
    const [containerHeight, setContainerHeight] = useState(window.innerHeight + HEIGHT_OFFSET); // Initial estimate
    const listRef = useRef<List>(null);

    useEffect(() => {
        const container = document.querySelector('.main-container');
        if (container) {
            setContainerHeight(container.clientHeight + HEIGHT_OFFSET);
        }

        const handleResize = () => {
            if (container) {
                setContainerHeight(container.clientHeight + HEIGHT_OFFSET);
            }
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    // Replace the static WINDOW_HEIGHT constant with containerHeight
    const CARD_HEIGHT = containerHeight - 50;

    // Listen to auth state changes and setup user document subscription
    useEffect(() => {
        const auth = getAuth();
        const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
            setUser(currentUser);

            // If user is logged in, subscribe to their document
            if (currentUser) {
                const userDocRef = doc(db, 'users', currentUser.uid);
                const unsubscribeDoc = onSnapshot(userDocRef, (docSnap) => {
                    if (docSnap.exists()) {
                        setCoins(docSnap.data()?.coins || 0);
                    }
                }, (error) => {
                    console.error('Error listening to user document:', error);
                });

                // Return cleanup function that unsubscribes from both auth and doc
                return () => {
                    unsubscribe();
                    unsubscribeDoc();
                };
            }

            // If no user, just return auth unsubscribe
            return () => unsubscribe();
        });
    }, []);

    // Check URL path on mount
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const id = params.get('id');
        if (id) {
            setInitialId(id);
        }
    }, []);

    // Move scrollToItem before the useEffect
    const scrollToItem = useCallback((index: number) => {
        listRef.current?.scrollToItem(index, "center");
    }, []);

    // Handle initial ID fetch
    useEffect(() => {
        const fetchInitialPrompt = async () => {
            if (!initialId) return;
            try {
                const docRef = doc(db, IMAGE_PROMPT_COLLECTION, initialId);
                const docSnap = await getDoc(docRef);

                if (docSnap.exists()) {
                    const initialPrompt = {
                        id: initialId,
                        ...docSnap.data(),
                        createdAt: docSnap.data().createdAt?.toDate()
                    } as ImagePrompt;

                    setRecentPrompts(prev => {
                        const deduped = deduplicatePrompts([initialPrompt, ...prev]);

                        setTimeout(() => {
                            scrollToItem(1);
                        }, SCROLL_DELAY);

                        return deduped;
                    });
                }
            } catch (error) {
                console.error('Error fetching initial prompt:', error);
            }
        };

        fetchInitialPrompt();
    }, [initialId]);


    // Fetch prompts function
    const fetchPrompts = useCallback(async (lastDocument?: any) => {
        if (isLoading || !hasMore || !shouldFetch) {
            return;
        }

        setIsLoading(true);
        setShouldFetch(false);

        try {
            let q = query(
                collection(db, IMAGE_PROMPT_COLLECTION),
                limit(CARDS_PER_PAGE)
            );

            // Add filter conditions based on currentFilter
            switch (currentFilter) {
                case 'my':
                    if (!user) {
                        setHasMore(false);
                        return;
                    }
                    q = query(q, where('userId', '==', user.uid), orderBy('createdAt', 'desc'));
                    break;
                case 'top':
                    q = query(collection(db, IMAGE_PROMPT_COLLECTION),
                        orderBy('createdAt', 'desc'),
                        orderBy('votes', 'desc'),
                        limit(CARDS_PER_PAGE));
                    break;
                case 'recent':
                    q = query(q, orderBy('createdAt', 'desc'));
                    break;
                case 'oldest':
                    q = query(q, orderBy('createdAt', 'asc'));
                    break;
                // ... other cases
            }

            if (lastDocument) {
                q = query(q, startAfter(lastDocument));
            }

            // Create the subscription
            const unsubscribe = onSnapshot(
                q,
                {
                    next: (querySnapshot) => {
                        const newPrompts = querySnapshot.docs.map(doc => ({
                            id: doc.id,
                            ...doc.data(),
                            createdAt: doc.data().createdAt?.toDate()
                        })) as ImagePrompt[];

                        setRecentPrompts(prev => {
                            // Create a map of existing prompts for quick lookup
                            const existingPromptsMap = new Map(prev.map(p => [p.id, p]));

                            // Update existing prompts with new values
                            newPrompts.forEach(newPrompt => {
                                const existing = existingPromptsMap.get(newPrompt.id);
                                if (existing) {
                                    existingPromptsMap.set(newPrompt.id, {
                                        ...existing,
                                        ...newPrompt
                                    });
                                } else {
                                    existingPromptsMap.set(newPrompt.id, newPrompt);
                                }
                            });

                            // Convert map back to array, maintaining order
                            return deduplicatePrompts(Array.from(existingPromptsMap.values()));
                        });

                        setLastDoc(querySnapshot.docs[querySnapshot.docs.length - 1]);
                        setIsLoading(false);
                    },
                    error: (error) => {
                        console.error('Snapshot error:', error);
                        setIsLoading(false);
                    }
                }
            );

            setQueryUnsubscribe(() => unsubscribe);

        } catch (error) {
            console.error('Error in fetchPrompts:', error);
            setIsLoading(false);
        }
    }, [isLoading, hasMore, shouldFetch, currentFilter, user]);

    // Reset prompts when filter changes
    useEffect(() => {
        if (queryUnsubscribe) {
            queryUnsubscribe();
        }
        setRecentPrompts([]);
        setLastDoc(null);
        setHasMore(true);
        setShouldFetch(true);
    }, [currentFilter]);

    // Initial load and pagination
    useEffect(() => {
        if (shouldFetch) {
            fetchPrompts(lastDoc);
        }
    }, [fetchPrompts, shouldFetch, lastDoc]);

    // Handle scroll to last card
    const handleLastCard = useCallback(async () => {
        if (hasMore && !isLoading) {
            setShouldFetch(true);
            return Promise.resolve();
        }
    }, [hasMore, isLoading]);

    const onSignIn = async () => {
        const auth = getAuth();
        const provider = new GoogleAuthProvider();
        try {
            await signInWithPopup(auth, provider);
        } catch (error) {
            console.error('Error signing in:', error);
        }
    };

    const handleDeletePrompt = useCallback((deletedId: string) => {
        setRecentPrompts(prev => prev.filter(prompt => prompt.id !== deletedId));
    }, []);

    const handleNewPrompt = useCallback(async (newPromptId: string) => {
        try {
            const docRef = doc(db, IMAGE_PROMPT_COLLECTION, newPromptId);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const newPrompt = {
                    id: newPromptId,
                    ...docSnap.data(),
                    createdAt: docSnap.data().createdAt?.toDate()
                } as ImagePrompt;

                // Get current ID from URL
                const params = new URLSearchParams(window.location.search);
                const currentId = params.get('id');

                setRecentPrompts(prev => {
                    // Find index of current ID
                    const currentIndex = currentId ? prev.findIndex(p => p.id === currentId) : -1;

                    // If current ID found, insert after it, otherwise insert at start
                    const newPrompts = [...prev];
                    if (currentIndex !== -1) {
                        newPrompts.splice(currentIndex + 1, 0, newPrompt);
                        setTimeout(() => {
                            scrollToItem(currentIndex + 2); // +2 because of header card
                        }, SCROLL_DELAY);
                    } else {
                        newPrompts.unshift(newPrompt);
                        setTimeout(() => {
                            scrollToItem(1);
                        }, SCROLL_DELAY);
                    }

                    return deduplicatePrompts(newPrompts);
                });
            }
        } catch (error) {
            console.error('Error fetching new prompt:', error);
        }
    }, [scrollToItem]);

    // 2. Memoize handlers
    const handleImageClick = useCallback(async (id: string) => {
        try {
            const docRef = doc(db, IMAGE_PROMPT_COLLECTION, id);
            const docSnap = await getDoc(docRef);

            if (docSnap.exists()) {
                const newPrompt = {
                    id: id,
                    ...docSnap.data(),
                    createdAt: docSnap.data().createdAt?.toDate()
                } as ImagePrompt;

                setRecentPrompts(prev => {
                    const deduped = deduplicatePrompts([newPrompt, ...prev]);
                    setTimeout(() => {
                        scrollToItem(1);
                    }, SCROLL_DELAY);
                    return deduped;
                });
            }
        } catch (error) {
            console.error('Error fetching prompt:', error);
        }
    }, [scrollToItem]);

    // 3. Memoize row renderer
    const rowRenderer = useCallback(({ index, style }: { index: number; style: React.CSSProperties }) => (
        <CardRow
            index={index}
            style={style}
            setIsCreateDialogOpen={setIsCreateDialogOpen}
            onImageClick={handleImageClick}
            prompt={getImageForPrompt(recentPrompts, index)}
            user={user}
            handleLastCard={handleLastCard}
            handleDeletePrompt={handleDeletePrompt}
            setSelectedCharacter={setSelectedCharacter}
            background={CARD_BACKGROUNDS[index % 2]}
        />
    ), [
        recentPrompts,
        user,
        handleLastCard,
        handleDeletePrompt,
        setSelectedCharacter,
        handleImageClick,
        setIsCreateDialogOpen
    ]);

    // 4. Memoize list props
    const listProps = useMemo(() => ({
        height: containerHeight - 50,
        // itemCount: recentPrompts.length + 1,
        itemCount: 1000,
        itemSize: CARD_HEIGHT,
        width: "100%",
        style: {
            scrollSnapType: window.innerWidth > 768 ? 'y mandatory' : 'y proximity',
            WebkitOverflowScrolling: 'touch' as const,
            overscrollBehavior: 'contain' as const,
            scrollBehavior: 'smooth' as const
        }
    }), [containerHeight, recentPrompts.length, CARD_HEIGHT]);

    // Add near other useEffects
    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === '!') {
                scrollToItem(1);
            }
        };

        window.addEventListener('keypress', handleKeyPress);
        return () => window.removeEventListener('keypress', handleKeyPress);
    }, [scrollToItem]);


    return (
        <>
            <div className="main-container">

                <List
                    {...listProps}
                    ref={listRef}>
                    {rowRenderer}
                </List>
            </div>
            <MainHeader
                isSignedIn={!!user}
                userName={user?.displayName || 'Guest'}
                coins={coins}
                onSignIn={onSignIn}
                setIsCreateDialogOpen={setIsCreateDialogOpen}
                setFilter={setCurrentFilter}
                currentFilter={currentFilter}
                selectedCharacter={selectedCharacter}
                onCloseCharacterDetails={() => setSelectedCharacter(null)}
            />
            <CardDialog
                isOpen={isCreateDialogOpen}
                onClose={() => setIsCreateDialogOpen(false)}
                background="#1a1e24"
            >
                <div className="create-dialog-content">
                    <h1>Create Your Character</h1>

                    <CardCharacterForm
                        onSubmit={(prompt, style, newPromptId) => {
                            if (newPromptId) {
                                handleNewPrompt(newPromptId);
                            }
                        }}
                        user={user}
                        onClose={() => setIsCreateDialogOpen(false)}
                    />

                </div>
            </CardDialog>
        </>
    );
};

export default Main; 