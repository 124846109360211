import React, { useState, useEffect } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { doc, getDoc, getFirestore } from 'firebase/firestore';
import './MainHeader.css';
import CardDialog from './CardDialog';
import { collection, getCountFromServer } from 'firebase/firestore';
import CharacterDetails from './CharacterDetails';
import { Timestamp } from 'firebase/firestore';
import { IMAGE_PROMPT_COLLECTION } from '../constants/clientConstants';
import { StyleType } from '../constants/styles';
import { ImagePrompt } from '../types';
import { getFunctions, httpsCallable } from 'firebase/functions';

interface MainHeaderProps {
    isSignedIn: boolean;
    userName: string;
    coins: number;
    onSignIn: () => void;
    hidden?: boolean;
    setIsCreateDialogOpen: (open: boolean) => void;
    setFilter: (filter: 'all' | 'my' | 'top' | 'recent' | 'oldest') => void;
    currentFilter: 'all' | 'my' | 'top' | 'recent' | 'oldest';
    selectedCharacter?: {
        id: string;
        prompt: string;
        style: StyleType;
        createdAt: Timestamp;
        userName: string | null;
        votes: number;
        videoStatus?: string;   
        videoError?: string;
        visibility?: 'PUBLIC' | 'PRIVATE';
        xId?: string;
        xError?: string;
        instagramId?: string;
        instagramError?: string;
        youtubeId?: string;
        youtubeError?: string;
    } | null;
    onCloseCharacterDetails: () => void;
}

interface Stats {
    imagePrompts: number;
    users: number;
}

const MainHeader: React.FC<MainHeaderProps> = ({
    isSignedIn,
    userName,
    coins,
    onSignIn,
    setIsCreateDialogOpen,
    setFilter,
    currentFilter,
    selectedCharacter,
    onCloseCharacterDetails
}) => {
    const [showFilterDialog, setShowFilterDialog] = useState(false);
    const [showSettingsMenu, setShowSettingsMenu] = useState(false);
    const [showStats, setShowStats] = useState(false);
    const [stats, setStats] = useState<Stats | null>(null);
    const [isAdmin, setIsAdmin] = useState(false);
    const [isCleanupDialogOpen, setIsCleanupDialogOpen] = useState(false);
    const [isCleaningUp, setIsCleaningUp] = useState(false);
    const [cleanupResults, setCleanupResults] = useState<{
        deletedFileCount: number;
        deletedVideoCount: number;
        totalFiles: number;
        totalVideos: number;
        totalPrompts: number;
    } | null>(null);

    // Check if user is admin
    useEffect(() => {
        const checkAdminStatus = async () => {
            const auth = getAuth();
            if (auth.currentUser) {
                const db = getFirestore();
                const userDoc = await getDoc(doc(db, 'users', auth.currentUser.uid));
                setIsAdmin(userDoc.data()?.isAdmin || false);
            }
        };
        
        checkAdminStatus();
    }, [isSignedIn]);

    const handleSignOut = async () => {
        const auth = getAuth();
        try {
            await signOut(auth);
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    const handleViewStats = async () => {
        setShowSettingsMenu(false);
        const db = getFirestore();
        
        const [promptsSnapshot, usersSnapshot] = await Promise.all([
            getCountFromServer(collection(db, IMAGE_PROMPT_COLLECTION)),
            getCountFromServer(collection(db, 'users'))
        ]);

        setStats({
            imagePrompts: promptsSnapshot.data().count,
            users: usersSnapshot.data().count
        });
        setShowStats(true);
    };

    const handleCleanup = async () => {
        setIsCleaningUp(true);
        try {
            const functions = getFunctions();
            const cleanupStorage = httpsCallable(functions, 'cleanupStorage');
            const result = await cleanupStorage();
            setCleanupResults(result.data as any);
        } catch (error) {
            console.error('Error cleaning up storage:', error);
        } finally {
            setIsCleaningUp(false);
        }
    };

    // Add click handler for document to close menu
    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            const settingsContainer = document.querySelector('.settings-container');
            if (settingsContainer && !settingsContainer.contains(event.target as Node)) {
                setShowSettingsMenu(false);
            }
        };

        document.addEventListener('click', handleClickOutside);
        return () => document.removeEventListener('click', handleClickOutside);
    }, []);

    return (
        <>
            <header className={`main-header`}>
                <div className="header-content">
                    <div 
                        className="header-brand-container"
                        onClick={() => setIsCreateDialogOpen(true)}
                        role="button"
                        tabIndex={0}
                    >
                        <div className="header-brand">
                            <span className="brand-my">my</span>
                            <span className="brand-guys">GUYS</span>
                        </div>
                        <div className="create-indicator">
                            <span className="create-plus">+</span>
                            <span className="create-text">Create</span>
                        </div>
                    </div>
                    <div className="filter-container">
                        <button 
                            className={`filter-button ${showFilterDialog ? 'active' : ''}`}
                            onClick={() => setShowFilterDialog(true)}
                            aria-label="Filter characters"
                        >
                            <svg 
                                width="20" 
                                height="20" 
                                viewBox="0 0 24 24" 
                                fill="none" 
                                stroke="currentColor" 
                                strokeWidth="2"
                            >
                                <polygon points="22 3 2 3 10 12.46 10 19 14 21 14 12.46 22 3"/>
                            </svg>
                        </button>
                    </div>
                    <div className="header-actions">
                        {isSignedIn ? (
                            <>
                                <div className="user-info">
                                    <span className="coins">💰 {coins.toLocaleString()}</span>
                                </div>
                                <div className="settings-container">
                                    <button 
                                        className="settings-button"
                                        onClick={(e) => {
                                            e.stopPropagation();  // Prevent document click from immediately closing
                                            setShowSettingsMenu(!showSettingsMenu);
                                        }}
                                        aria-label="Settings"
                                    >
                                        <svg 
                                            width="20" 
                                            height="20" 
                                            viewBox="0 0 24 24" 
                                            fill="none" 
                                            stroke="currentColor" 
                                            strokeWidth="2"
                                        >
                                            <circle cx="12" cy="12" r="3"/>
                                            <path d="M19.4 15a1.65 1.65 0 0 0 .33 1.82l.06.06a2 2 0 0 1 0 2.83 2 2 0 0 1-2.83 0l-.06-.06a1.65 1.65 0 0 0-1.82-.33 1.65 1.65 0 0 0-1 1.51V21a2 2 0 0 1-2 2 2 2 0 0 1-2-2v-.09A1.65 1.65 0 0 0 9 19.4a1.65 1.65 0 0 0-1.82.33l-.06.06a2 2 0 0 1-2.83 0 2 2 0 0 1 0-2.83l.06-.06a1.65 1.65 0 0 0 .33-1.82 1.65 1.65 0 0 0-1.51-1H3a2 2 0 0 1-2-2 2 2 0 0 1 2-2h.09A1.65 1.65 0 0 0 4.6 9a1.65 1.65 0 0 0-.33-1.82l-.06-.06a2 2 0 0 1 0-2.83 2 2 0 0 1 2.83 0l.06.06a1.65 1.65 0 0 0 1.82.33H9a1.65 1.65 0 0 0 1-1.51V3a2 2 0 0 1 2-2 2 2 0 0 1 2 2v.09a1.65 1.65 0 0 0 1 1.51 1.65 1.65 0 0 0 1.82-.33l.06-.06a2 2 0 0 1 2.83 0 2 2 0 0 1 0 2.83l-.06.06a1.65 1.65 0 0 0-.33 1.82V9a1.65 1.65 0 0 0 1.51 1H21a2 2 0 0 1 2 2 2 2 0 0 1-2 2h-.09a1.65 1.65 0 0 0-1.51 1z"/>
                                        </svg>
                                    </button>
                                    {showSettingsMenu && (
                                        <div className="settings-menu">
                                            {isAdmin && (
                                                <>
                                                    <button onClick={handleViewStats}>View Stats</button>
                                                    <button onClick={() => setIsCleanupDialogOpen(true)}>Cleanup Storage</button>
                                                </>
                                            )}
                                            <button onClick={handleSignOut}>Sign Out</button>
                                        </div>
                                    )}
                                </div>
                            </>
                        ) : (
                            <button 
                                className="sign-in-button"
                                onClick={(e)=>{
                                    e.stopPropagation();
                                    setShowSettingsMenu(false);
                                    if (onSignIn) {
                                        onSignIn();
                                    }
                                }}
                            >
                                Sign In
                            </button>
                        )}
                    </div>
                </div>
            </header>

            <CardDialog
                isOpen={showFilterDialog}
                onClose={() => setShowFilterDialog(false)}
                background="#1a1e24"
            >
                <div className="filter-dialog-content">
                    <h2>Filter Images</h2>
                    <div className="filter-options">
                        <button 
                            className={`filter-dialog-option ${currentFilter === 'all' ? 'active' : ''}`}
                            onClick={() => { 
                                setFilter('all'); 
                                setShowFilterDialog(false); 
                            }}
                        >
                            <span className="option-icon">🌎</span>
                            <div className="option-text">
                                <h3>All Images</h3>
                                <p>View all created characters</p>
                            </div>
                        </button>
                        <button 
                            className={`filter-dialog-option ${currentFilter === 'my' ? 'active' : ''}`}
                            onClick={() => { 
                                setFilter('my'); 
                                setShowFilterDialog(false); 
                            }}
                        >
                            <span className="option-icon">👤</span>
                            <div className="option-text">
                                <h3>My Images</h3>
                                <p>View only your characters</p>
                            </div>
                        </button>
                        <button 
                            className={`filter-dialog-option ${currentFilter === 'top' ? 'active' : ''}`}
                            onClick={() => { 
                                setFilter('top'); 
                                setShowFilterDialog(false); 
                            }}
                        >
                            <span className="option-icon">⭐</span>
                            <div className="option-text">
                                <h3>Top Images</h3>
                                <p>View highest voted characters</p>
                            </div>
                        </button>
                        <button 
                            className={`filter-dialog-option ${currentFilter === 'recent' ? 'active' : ''}`}
                            onClick={() => { 
                                setFilter('recent'); 
                                setShowFilterDialog(false); 
                            }}
                        >
                            <span className="option-icon">🕒</span>
                            <div className="option-text">
                                <h3>Most Recent</h3>
                                <p>View newest characters first</p>
                            </div>
                        </button>
                        <button 
                            className={`filter-dialog-option ${currentFilter === 'oldest' ? 'active' : ''}`}
                            onClick={() => { 
                                setFilter('oldest'); 
                                setShowFilterDialog(false); 
                            }}
                        >
                            <span className="option-icon">📅</span>
                            <div className="option-text">
                                <h3>Oldest to Newest</h3>
                                <p>View oldest characters first</p>
                            </div>
                        </button>
                    </div>
                </div>
            </CardDialog>

            <CardDialog
                isOpen={showStats}
                onClose={() => setShowStats(false)}
                background="#1a1e24"
            >
                <div className="stats-dialog">
                    <h2>Platform Stats</h2>
                    {stats && (
                        <div className="stats-content">
                            <div className="stat-item">
                                <label>Total Images:</label>
                                <span>{stats.imagePrompts.toLocaleString()}</span>
                            </div>
                            <div className="stat-item">
                                <label>Total Users:</label>
                                <span>{stats.users.toLocaleString()}</span>
                            </div>
                        </div>
                    )}
                </div>
            </CardDialog>

            {selectedCharacter && (
                <CardDialog
                    isOpen={!!selectedCharacter}
                    onClose={onCloseCharacterDetails}
                    background="#1a1e24"
                >
                    <CharacterDetails
                        id={selectedCharacter.id}
                        cachedImagePrompt={{
                            ...selectedCharacter,
                            videoStatus: selectedCharacter.videoStatus as 'SUCCEEDED' | 'FAILED' | 'PENDING' | 'NOT_STARTED' | 'THROTTLED' | 'RUNNING' | 'STARTED'
                        } as ImagePrompt}
                        isAdmin={isAdmin}
                    />
                </CardDialog>
            )}

            {isAdmin && (
                <CardDialog
                    isOpen={isCleanupDialogOpen}
                    onClose={() => {
                        setIsCleanupDialogOpen(false);
                        setCleanupResults(null);
                    }}
                    background="#1a1e24"
                >
                    <div className="cleanup-dialog">
                        <h2>Cleanup Storage</h2>
                        {!cleanupResults ? (
                            <>
                                <p>This will remove orphaned files from storage that don't have corresponding database entries.</p>
                                <div className="cleanup-actions">
                                    <button 
                                        onClick={() => setIsCleanupDialogOpen(false)}
                                        className="cancel-button"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleCleanup}
                                        className="cleanup-button"
                                        disabled={isCleaningUp}
                                    >
                                        {isCleaningUp ? 'Cleaning...' : 'Start Cleanup'}
                                    </button>
                                </div>
                            </>
                        ) : (
                            <div className="cleanup-results">
                                <p>Cleanup completed:</p>
                                <ul>
                                    <li>Deleted files: {cleanupResults.deletedFileCount}</li>
                                    <li>Deleted videos: {cleanupResults.deletedVideoCount}</li>
                                    <li>Total files: {cleanupResults.totalFiles}</li>
                                    <li>Total videos: {cleanupResults.totalVideos}</li>
                                    <li>Total prompts: {cleanupResults.totalPrompts}</li>
                                </ul>
                                <button 
                                    onClick={() => {
                                        setIsCleanupDialogOpen(false);
                                        setCleanupResults(null);
                                    }}
                                    className="close-button"
                                >
                                    Close
                                </button>
                            </div>
                        )}
                    </div>
                </CardDialog>
            )}
        </>
    );
};

export default MainHeader; 