import React, { useEffect, useRef, useCallback } from 'react';
import './Card.css';

interface CardProps {
    children: React.ReactNode;
    background?: string;
    isLastCard?: boolean;
    onLastCardVisible?: () => Promise<void>;
    id: string;
    className?: string;
}

const Card: React.FC<CardProps> = ({ children, background, isLastCard, onLastCardVisible, id, className }) => {
    const cardRef = useRef<HTMLDivElement>(null);

    const updateUrl = useCallback((cardId: string) => {
        const newUrl = `${window.location.pathname}?id=${cardId}`;
        window.history.replaceState({}, '', newUrl);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        // Update URL when card is in view
                        updateUrl(id);
                        
                        // Handle last card visibility
                        if (isLastCard && onLastCardVisible) {
                            onLastCardVisible();
                        }
                    }
                });
            },
            {
                threshold: 0.5  // Trigger when 50% of card is visible
            }
        );

        if (cardRef.current) {
            observer.observe(cardRef.current);
        }

        return () => {
            if (cardRef.current) {
                observer.unobserve(cardRef.current);
            }
        };
    }, [id, isLastCard, onLastCardVisible, updateUrl]);

    return (
        <div
            ref={cardRef}
            className={`card ${className || ''}`}
            style={{ background }}
            id={`card-${id}`}
        >
            {children}
        </div>
    );
};

export default Card; 