import { User } from 'firebase/auth';
import { deleteDoc, doc, Timestamp } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import React, { useMemo, useState } from 'react';
import { copyToClipboard } from 'utils/numbers';
import { getCachedImageUrl, IMAGE_PROMPT_COLLECTION } from '../constants/clientConstants';
import { styleConfigs, StyleType } from '../constants/styles';
import { db } from '../firebase';
import { useIsAdmin } from '../hooks/useIsAdmin';
import { ImagePrompt } from '../types';
import { downloadImage } from '../utils/download';
import './CharacterCard.css';
import { fireConfetti } from './Confetti';
import ImageContainer from './ImageContainer';

interface CharacterCardProps {
    id: string;
    imagePrompt: ImagePrompt;
    user: User | null;
    onDelete?: (id: string) => void;
    onShowDetails: (character: {
        id: string;
        prompt: string;
        style: StyleType;
        createdAt: Timestamp;
        userName: string | null;
        votes: number;
        videoStatus?: string;
        visibility?: 'PUBLIC' | 'PRIVATE';
        xId?: string;
        instagramId?: string;
        youtubeId?: string;
        xError?: string;
        instagramError?: string;
        youtubeError?: string;
        title?: string;
    }) => void;
}



const CharacterCard: React.FC<CharacterCardProps> = ({ id, imagePrompt, user, onDelete, onShowDetails }) => {
    const isAdmin = useIsAdmin(user);
    const [isLoading, setIsLoading] = useState(false);
    const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
    const [showVideo, setShowVideo] = useState(true);
    const functions = getFunctions();
    const handleVoteFunction = httpsCallable(functions, 'handleVote');
    const [isVoting, setIsVoting] = useState(false);
    const [votes, setVotes] = useState(imagePrompt.votes);


    const handleDeleteClick = () => {
        //setShowDeleteConfirm(true);
        handleConfirmDelete()
    };

    const handleConfirmDelete = async () => {
        try {
            await deleteDoc(doc(db, IMAGE_PROMPT_COLLECTION, id));
            onDelete?.(id);
        } catch (error) {
            console.error('Error deleting prompt:', error);
        } finally {
            setShowDeleteConfirm(false);
        }
    };

    const handleShare = async () => {
        const shareUrl = `${window.location.origin}?id=${id}`;

        if (navigator.share) {
            try {
                copyToClipboard(shareUrl)

                await navigator.share({
                    title: `${imagePrompt!.title || imagePrompt!.prompt} - My Guys Character`,
                    text: `Check out this ${styleName} character I created`,
                    url: shareUrl
                });
            } catch (error) {
                console.error('Error sharing:', error);
            }
        } else {
            // Fallback to copying to clipboard
            try {
                copyToClipboard(shareUrl)

                await navigator.clipboard.writeText(shareUrl);
                // You might want to show a toast notification here
                alert('Link copied to clipboard!');
            } catch (error) {
                console.error('Error copying to clipboard:', error);
            }
        }
    };

    const handleVote = async (event: React.MouseEvent<HTMLButtonElement>) => {
        if (!user || isVoting) return;

        setIsVoting(true);
        try {
            fireConfetti(event.target as HTMLElement);
            setVotes(votes + 1);
            await handleVoteFunction({ 
                id: id,
                prompt: imagePrompt.prompt,
                fullPrompt: imagePrompt.fullPrompt,
                style: imagePrompt.style,
                styleName: imagePrompt.styleName,
                animationPrompt: imagePrompt.animationPrompt,
                imageUrl: imagePrompt.imageUrl,
                seed: imagePrompt.seed,
                width: imagePrompt.width,
                height: imagePrompt.height,
                model: imagePrompt.model,
                votes: imagePrompt.votes + 1,
                userId: user?.uid,
                userName: user?.displayName
             });
        } catch (error) {
            console.error('Error voting:', error);
        } finally {
            setIsVoting(false);
        }
    };

    const handleShowDetails = () => {
        if (imagePrompt) {
            onShowDetails({
                id: id,
                prompt: imagePrompt.prompt,
                style: imagePrompt.style as StyleType,
                createdAt: imagePrompt.createdAt,
                userName: imagePrompt.userName,
                votes: imagePrompt.votes,
                videoStatus: imagePrompt.videoStatus,
                visibility: imagePrompt.visibility,
                xId: imagePrompt.xId,
                xError: imagePrompt.xError,
                instagramId: imagePrompt.instagramId,
                instagramError: imagePrompt.instagramError,
                youtubeId: imagePrompt.youtubeId,
                youtubeError: imagePrompt.youtubeError
            });
        }
    };

    // Add useMemo for URLs
    const urls = useMemo(() => ({
        imageUrl: getCachedImageUrl(id, imagePrompt.imageUrl)
    }), [id]); // Only recalculate if id changes

    if (isLoading) {
        return <div className="character-card loading" />;
    }

    if (!imagePrompt) {
        return null;
    }

    const runwayVideoUrl = imagePrompt.runwayVideoUrl
    const styleName = styleConfigs[imagePrompt.style as StyleType]?.name || imagePrompt.style;
    const hasVideo = !!imagePrompt?.runwayVideoUrl;
    const displayVideo = hasVideo && showVideo;
    const downloadUrl = displayVideo ? runwayVideoUrl : urls.imageUrl;
    const downloadName = displayVideo ? `myguys-${id}.mp4` : `myguys-${id}.jpg`;
    const showVideoButton = hasVideo;

    return (
        <>
            <ImageContainer 
                imageUrl={urls.imageUrl}
                videoUrl={runwayVideoUrl}
                showVideo={showVideo}
                prompt={imagePrompt?.prompt}
            />
            <div className="character-details" >
                <button
                    className={`votes-badge ${isVoting ? 'voting' : ''} ${!user ? 'disabled' : ''}`}
                    onClick={handleVote}
                    disabled={!user || isVoting}
                    title={user ? 'Click to vote' : 'Sign in to vote'}
                >
                    {isVoting ? '⏳' : '💰'} {votes}
                </button>
                <div style={{ flex: 1 }} onClick={handleShowDetails} >
                    <p className="character-prompt">{imagePrompt.title || imagePrompt.prompt}</p>
                </div>
                {imagePrompt && (
                    <button
                        className="share-button"
                        onClick={handleShare}
                        aria-label="Share character"
                    >
                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                            <circle cx="18" cy="5" r="3" />
                            <circle cx="6" cy="12" r="3" />
                            <circle cx="18" cy="19" r="3" />
                            <line x1="8.59" y1="13.51" x2="15.42" y2="17.49" />
                            <line x1="15.41" y1="6.51" x2="8.59" y2="10.49" />
                        </svg>
                    </button>
                )}
            </div>
            <div className="character-button-bar">
                {isAdmin &&
                    <button
                        className="delete-button"
                        onClick={handleDeleteClick}
                        aria-label="Delete character"
                    >
                        <svg
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                        >
                            <path d="M3 6h18M19 6v14a2 2 0 01-2 2H7a2 2 0 01-2-2V6m3 0V4a2 2 0 012-2h4a2 2 0 012 2v2" />
                        </svg>
                    </button>
                }
                <div className="style-badge">
                    {styleName}
                </div>
                {showVideoButton && (
                    <button
                        className="toggle-view-button"
                        onClick={() => setShowVideo(!showVideo)}
                        aria-label={showVideo ? "Show image" : "Show video"}
                    >
                        {showVideo ? (
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <rect x="2" y="2" width="20" height="20" rx="2" ry="2" />
                                <circle cx="12" cy="12" r="3" />
                            </svg>
                        ) : (
                            <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <polygon points="5 3 19 12 5 21 5 3" />
                            </svg>
                        )}
                    </button>
                )}
                <button
                    className="action-button"
                    onClick={() => downloadImage(runwayVideoUrl!, downloadName)}
                    aria-label="Download character"
                >
                    <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                        <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                        <polyline points="7 10 12 15 17 10" />
                        <line x1="12" y1="15" x2="12" y2="3" />
                    </svg>
                </button>

                {isAdmin && (
                    <>
                        {imagePrompt.xId && (
                            <a
                                href={`https://x.com/i/status/${imagePrompt.xId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-button x-button"
                                aria-label="View on X"
                            >
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M18.244 2.25h3.308l-7.227 8.26 8.502 11.24H16.17l-5.214-6.817L4.99 21.75H1.68l7.73-8.835L1.254 2.25H8.08l4.713 6.231zm-1.161 17.52h1.833L7.084 4.126H5.117z" />
                                </svg>
                            </a>
                        )}
                        {imagePrompt.instagramId && (
                            <a
                                href={`https://instagram.com/p/${imagePrompt.instagramId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-button instagram-button"
                                aria-label="View on Instagram"
                            >
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                    <rect x="2" y="2" width="20" height="20" rx="5" ry="5" />
                                    <path d="M16 11.37A4 4 0 1 1 12.63 8 4 4 0 0 1 16 11.37z" />
                                    <line x1="17.5" y1="6.5" x2="17.51" y2="6.5" />
                                </svg>
                            </a>
                        )}
                        {imagePrompt.youtubeId && (
                            <a
                                href={`https://youtube.com/shorts/${imagePrompt.youtubeId}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="social-button youtube-button"
                                aria-label="View on YouTube"
                            >
                                <svg width="20" height="20" viewBox="0 0 24 24" fill="currentColor">
                                    <path d="M23.498 6.186a3.016 3.016 0 0 0-2.122-2.136C19.505 3.545 12 3.545 12 3.545s-7.505 0-9.377.505A3.017 3.017 0 0 0 .502 6.186C0 8.07 0 12 0 12s0 3.93.502 5.814a3.016 3.016 0 0 0 2.122 2.136c1.871.505 9.376.505 9.376.505s7.505 0 9.377-.505a3.015 3.015 0 0 0 2.122-2.136C24 15.93 24 12 24 12s0-3.93-.502-5.814zM9.545 15.568V8.432L15.818 12l-6.273 3.568z" />
                                </svg>
                            </a>
                        )}

                        {showDeleteConfirm && (
                            <div className="delete-confirm">
                                <p>Delete this character?</p>
                                <div className="delete-actions">
                                    <button
                                        className="confirm-button"
                                        onClick={handleConfirmDelete}
                                    >
                                        Delete
                                    </button>
                                    <button
                                        className="cancel-button"
                                        onClick={() => setShowDeleteConfirm(false)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </div>
                        )}
                    </>
                )}
            </div>
        </>
    );
};

export default CharacterCard; 