
export const FIREBASE_STORAGE_URL = 'https://firebasestorage.googleapis.com/v0/b/funko-fec28.firebasestorage.app/o/';

export const getCachedImageUrl = (id: string, imageUrl?: string) => {
    if (imageUrl?.startsWith('https://')) {
        return imageUrl;
    }else{
        return `${FIREBASE_STORAGE_URL}images%2F${id}.jpg?alt=media`;
    }
}

export const getVideoUrl = (videoId: string) => {
    return `${FIREBASE_STORAGE_URL}videos%2F${videoId}.mp4?alt=media`;
}

export const IMAGE_PROMPT_COLLECTION = 'imagePrompts';

export const SCROLL_DELAY = 300;