import React, { useState, useEffect, memo } from 'react';
import './Title.css';

interface TitleProps {
    styles: string[];
    interval?: number;
    className?: string;
}

const Title: React.FC<TitleProps> = memo(({ styles, interval = 4000, className = '' }) => {
    const [displayText, setDisplayText] = useState('');
    const [isAnimating, setIsAnimating] = useState(false);

    const handleClick = () => {
        window.location.href = '/';
    };

    useEffect(() => {
        let currentIndex = 0;

        const typeWriter = (text: string) => {
            let i = 0;
            setDisplayText('');
            const typing = setInterval(() => {
                if (i <= text.length) {
                    setDisplayText(text.substring(0, i));
                    i++;
                } else {
                    clearInterval(typing);
                }
            }, 100);
        };

        const rotateTitle = () => {
            setIsAnimating(true);
            setTimeout(() => {
                currentIndex = (currentIndex + 1) % styles.length;
                typeWriter(styles[currentIndex]);
                setIsAnimating(false);
            }, 500);
        };

        // Set initial style with typewriter effect
        typeWriter(styles[0]);

        const intervalId = setInterval(rotateTitle, interval);
        return () => clearInterval(intervalId);
    }, [styles, interval]);

    return (
        <div className={`title-wrapper ${className}`} onClick={handleClick} style={{ cursor: 'pointer' }}>
            <div className={`title-slide ${isAnimating ? 'slide-out' : ''}`}>
                <span className="style-text">{displayText}</span>
            </div>
        </div>
    );
});

// Add display name for debugging
Title.displayName = 'Title';

export default Title; 