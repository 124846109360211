import React, { useState, useMemo, useEffect, useRef } from 'react';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { styleConfigs, getSelectedStyle } from '../constants/styles';
import { StyleType } from '../constants/styles';
import { generateImage } from '../utils/promptGenerator';
import { User } from 'firebase/auth';
import './CardCharacterForm.css';
import { getFullImagePrompt } from '../utils/promptGenerator';

interface CardCharacterFormProps {
    onSubmit: (prompt: string, style: StyleType, newPromptId?: string, imageData?: string) => void;
    user: User | null;
    onClose?: () => void;
}

function generateRandomNumber() {
    const min = 1;
    const max = 1000000;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
}
const CardCharacterForm: React.FC<CardCharacterFormProps> = ({
    onSubmit,
    user,
    onClose
}) => {
    const [prompt, setPrompt] = useState('');
    const [selectedStyle, setSelectedStyle] = useState<StyleType>('funko');
    const [imageUrl, setImageUrl] = useState<string>('');
    const [isLoading, setIsLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [isAiLoading, setIsAiLoading] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [imageWidth, setImageWidth] = useState(768);
    const [imageHeight, setImageHeight] = useState(1280);
    const [imageModel, setImageModel] = useState('flux'); // flux-3d, flux, flux-2d
    const [isLandscape, setIsLandscape] = useState(false);
    const [title, setTitle] = useState('');
    const functions = getFunctions();
    const savePromptFunction = httpsCallable(functions, 'savePrompt');
    const getPromptFunction = httpsCallable(functions, 'getPrompt');

    const [isSaving, setIsSaving] = useState(false);
    const [saveError, setError] = useState<string | null>(null);
    const [showSaveButton, setShowSaveButton] = useState(false);

    // Memoize alphabetized styles
    const alphabetizedStyles = useMemo(() => {
        return Object.entries(styleConfigs)
            .sort(([, a], [, b]) => a.name.localeCompare(b.name));
    }, []);

    useEffect(() => {
        if (isLandscape) {
            setImageWidth(1280);
            setImageHeight(768);
        } else {
            setImageWidth(768);
            setImageHeight(1280);
        }
    }, [isLandscape]);

    const onCancel = () => {
        setPrompt('');
        setImageUrl('');
        setImageLoading(false);
    };

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (isSubmitting) return;

        setIsSubmitting(true);
        try {

            if (!prompt.trim()) return;

            setIsLoading(true);
            setImageLoading(true);
            try {
                const styleConfig = styleConfigs[selectedStyle];
                const fullPrompt = `${prompt}. ${styleConfig.prompt}`;

                console.log('Full Prompt:', fullPrompt);

                const url = generateImage(fullPrompt, imageModel, imageWidth, imageHeight);
                setImageUrl(url + '&seed=' + generateRandomNumber());

                onSubmit(prompt, selectedStyle);
            } catch (error) {
                console.error('Error generating image:', error);
            } finally {
                setIsLoading(false);
            }

        } catch (error) {
            console.error('Error submitting form:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    // Add function to get image bytes
    const getImageBytes = async (url: string): Promise<string> => {
        try {
            const response = await fetch(url);
            const blob = await response.blob();
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onloadend = () => {
                    if (typeof reader.result === 'string') {
                        resolve(reader.result);
                    } else {
                        reject(new Error('Failed to convert image to base64'));
                    }
                };
                reader.onerror = reject;
                reader.readAsDataURL(blob);
            });
        } catch (error) {
            console.error('Error getting image bytes:', error);
            throw error;
        }
    };

    interface SavePromptResult {
        id: string;
        error?: string;
    }

    const handleImageLoad = () => {
        setImageLoading(false);
        setShowSaveButton(true);
    };

    const handleSave = async () => {
        setIsSaving(true);
        setError(null);
        try {
            const imgElement = document.getElementById('create-img-dialog') as HTMLImageElement;
            if (!imgElement) {
                throw new Error('Image element not found');
            }
            const fullImageUrl = imageUrl?.startsWith('https://') ? imageUrl : '';
            let imageData = '';
            if (!fullImageUrl) {
                imageData = await getImageBytes(imgElement.src);
            }
            const selectedStyleConfig = getSelectedStyle(selectedStyle);

            const stylePrompt = selectedStyleConfig.prompt;
            const styleName = selectedStyleConfig.name;
            const fPrompt = getFullImagePrompt(prompt, stylePrompt);
            const titlePrompt = title ? title : '';
            
            const result = await savePromptFunction({
                prompt,
                title:titlePrompt,
                fullPrompt: fPrompt,
                style: selectedStyleConfig.style,
                styleName,
                animationPrompt: animationPrompt || selectedStyleConfig.animationPrompt,
                imageData,
                imageUrl:fullImageUrl,
                seed: generateRandomNumber(),
                width: imageWidth,
                height: imageHeight,
                model: imageModel,
                votes: 0,
                userId: user?.uid || null,
                userName: user?.displayName || null
            });

            const data = result.data as SavePromptResult;

            if (data.error) {
                throw new Error(data.error);
            }

            onSubmit(prompt, selectedStyle, data.id);
            onClose?.();
        } catch (error) {
            console.error('Error saving prompt:', error);
            setError(error instanceof Error ? error.message : 'Failed to save character');
        } finally {
            setIsSaving(false);
        }
    };


    const handleAIPrompt = async () => {
        setIsAiLoading(true);
        try {
            const result: any = await getPromptFunction({
                newItem: prompt,
                style: selectedStyle
            });
            setPrompt(result.data.data);
        } catch (error) {
            console.error('Error with AI prompt:', error);
        } finally {
            setIsAiLoading(false);
        }
    };

    const handleCancel = () => {
        setShowSaveButton(false);
        setImageUrl('');
        setImageLoading(false);
    };

    // Add error state
    const [imageError, setImageError] = useState(false);

    // Add to state
    const [animationPrompt, setAnimationPrompt] = useState('');

    const fileInputRef = useRef<HTMLInputElement>(null);

    const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImageUrl(reader.result as string);
                setSelectedStyle('custom');
            };
            reader.readAsDataURL(file);
        }
    };

    return (
        <form className="card-character-form" onSubmit={handleSubmit}>
            {(!imageUrl || imageLoading) &&
                <>
                    <div className="form-group">
                        <div className="prompt-header">
                            <label htmlFor="character-prompt">Describe your character</label>
                            <div className="prompt-actions">
                                <input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleImageUpload}
                                    ref={fileInputRef}
                                    style={{ display: 'none' }}
                                />
                                {!imageUrl &&
                                    <button
                                        type="button"
                                        className="orientation-button"
                                        onClick={() => fileInputRef.current?.click()}
                                        data-tooltip="Upload image"
                                    >
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4" />
                                            <polyline points="17 8 12 3 7 8" />
                                            <line x1="12" y1="3" x2="12" y2="15" />
                                        </svg>
                                    </button>
                              }
                                <button
                                    type="button"
                                    className="orientation-button"
                                    onClick={() => setIsLandscape(!isLandscape)}
                                    data-tooltip={isLandscape ? "Switch to portrait" : "Switch to landscape"}
                                >
                                    {isLandscape ? (
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                            <rect x="3" y="5" width="18" height="14" rx="2" />
                                        </svg>
                                    ) : (
                                        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                            <rect x="5" y="3" width="14" height="18" rx="2" />
                                        </svg>
                                    )}
                                </button>
                                <button
                                    type="button"
                                    className={`ai-button ${isAiLoading ? 'loading' : ''}`}
                                    onClick={handleAIPrompt}
                                    disabled={isAiLoading}
                                    data-tooltip={prompt.trim() ? 'Enhance description' : 'Generate description'}
                                >
                                    <span>✨</span>
                                </button>
                            </div>
                        </div>
                        <textarea
                            id="character-prompt"
                            value={prompt}
                            onChange={(e) => setPrompt(e.target.value)}
                            placeholder="Describe your character here..."
                            rows={3}
                            required
                        />
                    </div>

                    <div className="form-group">
                        <label htmlFor="character-style">Choose a style</label>
                        <select
                            id="character-style"
                            value={selectedStyle}
                            onChange={(e) => setSelectedStyle(e.target.value as StyleType)}
                        >
                            {alphabetizedStyles.map(([key, config]) => (
                                <option key={key} value={key}>
                                    {config.name}
                                </option>
                            ))}
                        </select>
                    </div>

                    <div className="form-group">
                        <label htmlFor="animation-prompt">Animation Description (Optional)</label>
                        <textarea
                            id="animation-prompt"
                            value={animationPrompt}
                            onChange={(e) => setAnimationPrompt(e.target.value)}
                            placeholder="Describe how the character should move and animate..."
                            rows={1}
                        />
                    </div>

                    <div className="form-actions">
                        {onCancel && (
                            <button
                                type="button"
                                onClick={onCancel}
                                className="cancel-button"
                            >
                                Cancel
                            </button>
                        )}
                        <button
                            type="submit"
                            className="submit-button"
                            disabled={!prompt.trim() || isLoading || imageLoading}
                        >
                            {isLoading || imageLoading ? 'Creating...' : 'Create Character'}
                        </button>
                    </div>
                </>
            }

            {imageUrl && (
                <div className="preview-container">
                    {showSaveButton && (
                        <div className="save-container">
                             <div className="form-group">
                                <label htmlFor="title">Title (Optional)</label>
                                <textarea
                                    id="title"
                                    value={title}
                                    onChange={(e) => setTitle(e.target.value)}
                                    placeholder="Enter a title for your character"
                                    rows={1}
                                />                                
                            </div>
                            <div className="form-group">
                                <label htmlFor="animation-prompt">Animation Description (Optional)</label>
                                <textarea
                                    id="animation-prompt"
                                    value={animationPrompt}
                                    onChange={(e) => setAnimationPrompt(e.target.value)}
                                    placeholder="Describe how the character should move and animate..."
                                    rows={1}
                                />
                            </div>
                            {saveError && (
                                <div className="error-message">
                                    {saveError}
                                </div>
                            )}
                            <div className="save-actions">
                                <button
                                    onClick={handleCancel}
                                    className="cancel-button"
                                    type="button"
                                >
                                    Cancel
                                </button>
                                <button
                                    onClick={handleSave}
                                    className="save-button"
                                    disabled={isSaving}
                                >
                                    {isSaving ? '⏳ Saving...' : 'Save Character'}
                                </button>
                            </div>
                        </div>
                    )}

                    <img
                        src={imageUrl}
                        id="create-img-dialog"
                        alt="Generated character"
                        onLoad={handleImageLoad}
                        onError={() => setImageError(true)}
                        className={imageLoading ? 'loading' : ''}
                        style={{ display: imageError ? 'none' : 'block' }}
                    />

                    {imageError && (
                        <div className="image-error">
                            <svg width="48" height="48" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
                                <circle cx="12" cy="12" r="10" />
                                <line x1="15" y1="9" x2="9" y2="15" />
                                <line x1="9" y1="9" x2="15" y2="15" />
                            </svg>
                            <span>Failed to load image</span>
                            <button
                                className="retry-button"
                                onClick={() => {
                                    setImageError(false);
                                    handleSubmit(new Event('submit') as any);
                                }}
                            >
                                Try Again
                            </button>
                        </div>
                    )}
                </div>
            )}
        </form>
    );
};

export default CardCharacterForm; 