export function generateRandomNumber() {
    const min = 1;
    const max = 1000000;
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    return randomNumber;
}

export const copyToClipboard = (url: string) => {
    navigator.clipboard.writeText(url).then(() => {
        const tempDiv = document.createElement('div');
        tempDiv.className = 'copy-success';
        tempDiv.textContent = 'Link copied to clipboard!';
        document.body.appendChild(tempDiv);

        setTimeout(() => {
            document.body.removeChild(tempDiv);
        }, 2000);
    }).catch(err => {
        console.error('Failed to copy:', err);
    });
};